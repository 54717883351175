// Step 1: Import your component
import * as React from 'react'

// Step 2: Define your component
const DIYSP = () => {
  return (
      <p>Coming Soon</p>
  )
}

// Step 3: Export your component
export default DIYSP
